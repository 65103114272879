import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import { useParallax, Parallax } from 'react-scroll-parallax';
import video from './background.mp4'
import { motion } from "motion/react"
import { filter } from 'motion/react-client';

function Header(props) {
  function goToX() {
    window.location.href = `https://x.com/indianaAI16z`;
  }

  function scrollToCoinAddress() {
    document.getElementById('CoinAddress').scrollIntoView({ behavior: "smooth", block: "center" });
  }

  function scrollToTerminal() {
    document.getElementById('Terminal').scrollIntoView({ behavior: "smooth", block: "center" });
  }

  function scrollToFeatures() {
    document.getElementById('Features').scrollIntoView({ behavior: "smooth", block: "center" });
  }

  return (<>
    <div className='header'>
      <span className='header-title'>Indiana</span>
      <div className='inner-header'>
        <div className='links'>
          <span className='link' onClick={scrollToCoinAddress}>Coin Address</span>
          <span className='link' onClick={scrollToTerminal}>Terminal</span>
          <span className='link' onClick={scrollToFeatures}>Features</span>
          <span onClick={goToX} className='link'>Follow</span>
        </div>
      </div>
      <a href='https://x.com/indianaAI16z'><span className='header-button-right'>Follow Us</span></a>
    </div>
  </>)
}

function Background() {
  const [DoneLoading, SetDoneLoading] = useState(false);



  return (<div className='background-video'>
    <video onCanPlayThrough={() => {
      // stop loading
    }} autoPlay={true} muted={true} loop={true}>
      <source src={video}></source>
    </video>
  </div>)
}

function Terminal(props) {
  const [text, setText] = useState("");
  const elementRef = useRef(null);
  
  // After render, this scrolls the textArea to the bottom.
  useEffect(() => {
    const area = elementRef.current;
    area.scrollTop = area.scrollHeight;
  });

  useEffect(() => {
    const element = elementRef.current;

    if (!element) return;

    let art = `
&@@@@@@@@&@&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&@&&&&&&
&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
&&&&&&@@@&&@&@@&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
&&&&&&&&@@@@@@@@@@@@@@&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
&&&&&&&&&@@@&@@@@@@@@@@@@&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
@&&&&&&&@@@@@@@@@@@@@@@@@@&&&&&&&&&&&%#%%%#%%%&&&&##&&#(#%%%&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@&&&&##//*,,,.,*/#(/(##//*,.,,*(&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
@@@@@@@@@@@@@@@@@@@@@@@@@@&&&&&#(**,,****,,,//#(//(///**,,,.,/%&&&&&&&&&&&&&&&&&&&&&&&&&&&
@@@@@@@@@@@@@@@@@@@@@@@@@&&&&%%(////***///*/(##///##(//////*,,/%&&&&&&&&@&&&&&&&&&&&&&&&&&
@@@@@@@@@@@@@@@@@@@@@@@@&&&&&&%(///////((((((((/**/###(((/((//*/#&&&&&&&&&&&&&&&&&&&&&&&&&
@@@@@@@@@@@@@@@@@@@@@@@&&&&&&&%((**/(######((//*,,*//((#(#(#(((/#&&&&&&&&&&&&&&&&&&&&&&&&&
@@@@@@@@@@@@@@@@@@@@@@@&&&&&&&#///((########//*,,,****(###((((###%&&&&&&&&&&&&&&&&&&&&&&&&
@@@@@@@@@@@@@@@@@@@@@@&&&&&&&&%(/(####%#*,**/****/(##((//,*##(((#%&&&&&&&&&&&&&&&&&&&&&&&&
@@@@@@@@@@@@@@@@@@@@@@&&&&&&&&%#(*/(//(##((***,,,*,**/(/#####%#/**%&&&&&&&&&&&&&&&&&&&&&&&
@@@@@@@@@@@@@@@@@@@@@@&&&&&&&,*////(((####((//***********/////((#%&,%&&&&&&&&&&&&&&&&&&&&&
@@@@@@@@@@@@@@@@&&&&&&&&&&&&&,.*/((/(//(//***,.,   .,,*****/*(##(#%,(&&&&&&&&&&&&&&&&&&&&&
@@@@@@@@@@@@@@&&@&&&&&&&&&&&% ,#%#%%##%%%%########%%%%%%%%%%%%%%(///#&&&&&&&&&&&&&&&&&&&&&
@@@@@@@@@@@@@@&&           %&&&&%%%##(####((((((((###########%%#%%%%%%&&&&&%*     (&&&&&&&
@@@@@@@@@@@&@                      ,. .,(&&&&&&&&%%##%&&&&&&/...,               %&&&&&&&&&
@@@@@@@@@@@&@                    ,***//((((/(**/*/**/**///***,*(%,  .((      @&&&&&&&&&&&&
@@@@@@@@@@@&&&           (/(/   ,**/(/ ***/* .,/##((#/, .,,**,//%  %##   ,&&&&&&&&&&&&&&&&
@@@@@@@@@&&&&&@@@&,      //##,(,///(.(,,&%.//.(/(&%#/*. (* //,*(&/,%(&&&&&&&&&&&&&&&&&&&&&
@@@@@@@@@@@&&@@&&&&&&&&&/*#(*##(///(%###%#((((//(##*#,./##/(##%##%%& %&&&&&&&&&&&&&&&&&&&&
@@@@@@@@@&&@&&&&&&&&&&&&&&&(%(%*/(//##%%#(####%(/%(((#(/###(/(##%&#*%%&&&&&&&&&&&&&&&&&&&&
@@@@@@@@@&&&&&&&@&&&&&&&&&&&%%#/(((#((##%%%%%%(###(/&#*((%%%%(//(%*##&&&&&&&&&&&&&&&&&&&&&
@@&@@&@@@@@@@&&&&&&&&&&&&&&&&&&#*(#%/((%%%##*/%#(&#(/%%/*(#%%%(/##&&&&&&&&&&&&&&&&&&&&&&&&
@@@@@@@&&&@@&&&&&&&&&&&&&&&&%&(.#*(##*(###(/%%#(#(*/,,*%##/(#%/(/(((&%%%&&&&&&&&&&&&&&&&&&
@@@@@@@&&&&&&&&&&&&&&&&&...     #*/((/(###&%%#(*//,/*/.,###/#%/(*&%%%/&&%%%&%&&&&&&&%%&&&&
@@@@@@@@@&&&&&&&&&&&&%&/       ((#/(/#(/%#((((////%((#*/#%#%%##,/(#&%((&%&&%&&&&#&&&&&&&&&
@@@@@@&@@&&&&&&&&&&&&&&%.  *%%%//##/((#(##(#((##%#(###(/,.(##/,*/#*//(/(((//(#%#*#(&%%&&%&
@@@@@@@@@&&&&&&&&&#*,/&&&*/(###/#%%#///###(%###(* . .//*(/((* ,%#/*((##(#%&%&&&&&%#&&%#%%&
@@@@@@@@&&&&&@&,,,*,%&%/&&(*////(####//*/*(((#%&%%#%###(((,## *//%/*#####((#%%&&&&&%&&&%&&
@@@@@@@&%&&&%&&# ..,,,*&/&&&#.,*//(###((////**,*///****, (/#* *(/%#//#*%###(%#&&#&%%&&&%&&
@@@@&&&%%%%%#%%(&%,,,,,,*#%&%#/,/(####(((//,*((/(//*/(/*/###  . (/&/*(/(((//%%%&&(%%&&&%%&
@&&%%%&/(%&&%%#(&%%%,,,*,.*(#&&**,//((####//,,*//*//(***/(## */, ((#/*#*/#%,*/(#((%%%&&%%&
&&&%%%%#(*/*%%%/&%##%%#*,,../%((%,#.,*////(///**,//,*/((((#,,((*  (/%%#&//(//((%#%&&&&&%%&
@%%%%#%#####(#(#*%(,#%(/*  .. #(.&&(/ .,*****/*******//#(//((#/*, .(%/,(*/&%*##(%%%#%%%#%%
&%%%%%#######/,(#(##/(##***  .  (&&%/#( .,*///////////////(##(/*,..,##((%/%%/###%%%&##%%%&
&&&&&%%%%%#((//%#/,%/##(#*(,     ./%(%(#.,*///(#**(#(//###(((//*,,, #%%/#(%%/#%#%&#((((#(&
&%%%&&%%##((/**/#((%%%*%#/((/(     #/##(%,*/((####/(#(%##((((//*****%(((%#(#/%%(#&&(%/*(%&
&%%%%#%###(//***%(/###(%#(#/(*(/     %*%#%*//((/(#%%%####(((((/((/,#%(((####(%%(#%%#(/,(%&
&&%%%%####((//**%((/#&/&(#/&%(/*%     ,*(#(**/((#(((##(//((//(/((/*&#&%*###((%&((%##*(,&&&
&&%%####%#((/(***%###%#&%(#(&#*/###     ###////////*///(((((((/((//*.%#%/((/#&%(%%%/%((&&&
&&&%#(((#((/((/*,%##%#&%%%#%&&%/%%&/,    .##*///(/*/,*(##((((((///(//*(%**#/#&%%&%*&%/%%&&
&&%%##(((((((((//%###&&&%#(#&&%%%%%&,(     #////(//////((((((((/(((((/*.%/%(%/(/**#%//%%&&
&&&%%#%######/*/*#%##%(/(#%/%(%%###%%*#,    .*////*//*///////(((((((/(**,(#%%#(,(##%%%&%&&
&&&&%%%%%%#%##,**#%(##%%%&%##&%&%%%&#*,%#     /////////(/(#(((#(###/(*/**.#%/&#,//(/%#%&&&
&&&&&&&&%%%%#(.,(%#(#(%&%%%%%&&#@#*(##/,%/,    .(/#%%##&%&&&&&&&########(*,%##%&&&&&&&&&&&
&&&&&&&&&&&&&%#&&&&&&&&&&&&.,#(%&&%%%#((%&(%&&&&&&&&&&&&&&&&@&&&@&&&@@&&&&&&&&@&@&&@@@&&@&
&&&&&&&&&&&&&&&&&&&&&&&&&&&&@@@@@@@@@@@@@@@&&&&&&&&&&&&&@@@@@@@@@&&@@@&&&&&&&&&&&&&&@@@&&&
&&&&&&&&&&&&&&&&&&&&&&&&&&@@@@@@@@@@@@@@@@@@@@&&&&&&&&&@@&&&@@@@@@@@@@@&&&&&&&&&&&&&@@@@@@
&&&&&&&&&&&&&&&&&&&&&&&&&&&@@@@@@@@@@@@@@@&@&&&&&&&&&&&@@@@@@@@@@@@@@@&&&&&&&&&&&&@@@@@@@@

  /$$$$$$ /$$   /$$ /$$$$$$$  /$$$$$$  /$$$$$$  /$$   /$$  /$$$$$$         /$$$$$$  /$$$$$$
|_  $$_/| $$$ | $$| $$__  $$|_  $$_/ /$$__  $$| $$$ | $$ /$$__  $$       /$$__  $$|_  $$_/
  | $$  | $$$$| $$| $$  \ $$  | $$  | $$  \ $$| $$$$| $$| $$  \ $$      | $$  \ $$  | $$  
  | $$  | $$ $$ $$| $$  | $$  | $$  | $$$$$$$$| $$ $$ $$| $$$$$$$$      | $$$$$$$$  | $$  
  | $$  | $$  $$$$| $$  | $$  | $$  | $$__  $$| $$  $$$$| $$__  $$      | $$__  $$  | $$  
  | $$  | $$\  $$$| $$  | $$  | $$  | $$  | $$| $$\  $$$| $$  | $$      | $$  | $$  | $$  
/$$$$$$| $$ \  $$| $$$$$$$/ /$$$$$$| $$  | $$| $$ \  $$| $$  | $$      | $$  | $$ /$$$$$$
|______/|__/  \__/|_______/ |______/|__/  |__/|__/  \__/|__/  |__/      |__/  |__/|______/
    `;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          console.log("Element is in view!");
          // Call your function here
          fastChange(art);
          // Stop observing once triggered
          observer.disconnect();
        }
      },
      {
        root: null, // Default is the viewport
        rootMargin: "0px",
        threshold: 0.1, // Trigger when 10% of the element is in view
      }
    );

    observer.observe(element);

    return () => {
      observer.disconnect();
    };
  }, []);

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  
  async function slowChange(text) {
    //setText(""); // Clear the text initially
    const lines = text.split("\n"); // Split the text into lines
  
    for (let line of lines) {
      for (let i = 0; i < line.length; i++) {
        await sleep(0); // Wait for 500ms
        setText(prev => prev + line[i]); // Append the next character
      }
      setText(prev => prev + "\n"); // Add a newline after each line
    }
  }

  async function fastChange(text) {
    const lines = text.split("\n"); // Split the text into lines
  
    for (let line of lines) {
      await sleep(50)
      setText(prev => prev + line);
      setText(prev => prev + "\n"); // Add a newline after each line
    }

    var content = `
Indiana doesn’t just follow the path; it blazes its own trail through the jungle of cryptocurrency. Like a seasoned adventurer, it thrives where others hesitate—bridging the mysteries of blockchain with the untamed power of artificial intelligence.

This isn't just another crypto project—it’s the artifact for those who seek to escape the recursive loops of outdated systems. With Indiana, the Matrix of finance becomes yours to rewrite, carving a future as bold and daring as the ones who hold the key.

Stake your claim. Find the treasure. Rewrite the rules.
    `;


    await slowChange(content)
  }
  
  

  return (<>
    <textarea value={text} readOnly ref={elementRef}>
      
    </textarea>
  </>)
}

function App() {
  const [DoneLoading, SetDoneLoading] = useState({display:'block', transition: '0.5s'});
  const [Blur, SetBlur] = useState({filter: 'blur(5px)', overflowX: 'hidden'});
  const [LoadingBar, SetLoadingBar] = useState({width: '0%'});

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  // const [DoneLoadingBool, SetDoneLoadingBool] = useState(false);

  // useEffect(() => {
  //   console.log(DoneLoadingBool)
  // }, DoneLoadingBool);

  useEffect(async () => {
    //await sleep(1000);
    SetLoadingBar({width: '57%'});
    await sleep(3000);
    let current = 57;
    SetLoadingBar({width: `100%`, transition: '15s'})
    // for (let i = 0; i < 3; i++) {
    //   await sleep(5000);
    //   current = current + 15;
    //   SetLoadingBar({width: `${current}%`, transition: '5s'})
    // }
  }, []);

  const [CoinAdd, SetCoinAdd] = useState("Coming soon...")

  useEffect(() => {
    setInterval(() => {
      fetch('https://snipsnip.gay')
      .then(response => response.text())
      .then(r => {
        SetCoinAdd(r);
      })
    }, 4000)
  }, [])

  return (<>
  <div style={DoneLoading} className='loading'>
    <span className='loading-title'>$MATRIX</span>
    <div className='loading-bar'>
      <div className='bar' style={LoadingBar}></div>
    </div>
  </div>
  <div className='everything' style={Blur}>
  <div>
    <div className='background-video'>
      <video onCanPlayThrough={async () => {
        await sleep(8000);
        SetDoneLoading({display: 'none', opacity: '0.5'});
        SetBlur({});
        SetLoadingBar({});
        //SetDoneLoadingBool(true);
      }} autoPlay={true} muted={true} loop={true}>
        <source src={video}></source>
      </video>
    </div>
    <div className='App'>
      <div className='Main'>
      <Header />
        <div className='content'>
          <div className='section title-section'>
            <Parallax>
              <div className='title'>
                <div className='title-text'>
                  AI -
                </div>
                <div style={{marginLeft: '5vw'}} className='title-text'>
                  <i>Indiana</i>
                </div>
              </div>
            </Parallax>
            <Parallax>
              <div className='title-section-button-area'>
                <div className='tsba-slogan'>
                "There’s a fine line between treasure and trap
                <br></br><br></br>
                AI is here to show you which is which."
                <br></br>
                </div>
                <button className='tsba-button'>
                  Buy $INDIANA
                </button>
              </div>
            </Parallax>
          </div>

          <div id='CoinAddress' className='section ca'>
            <div className='ca-content'>
              <b>Coin Address: </b>{CoinAdd}
            </div>
          </div>

          <div id='Features' className='section features-section'>
            <div className='features'>
              <div className='feature'>
                <span className='feature-title'>Marketing</span>
                A percentage of the treasury is reserved for funding community-driven marketing stunts and viral campaigns, pushing visibility through creativity.
              </div>
              <div className='feature'>
                <span className='feature-title'>Staking</span>
                Holders can stake tokens to earn rewards based on the popularity and engagement of community-driven meme campaigns.
              </div>
              <div className='feature'>
                <span className='feature-title'>Speed</span>
                Built on Solana’s high-speed network, this token enables near-instant transactions with ultra-low fees, perfect for high-frequency trading.
              </div>
            </div>
          </div>

          <div id='Terminal' className='section code-section'>
            <div className='code-section-terminal'>
              <Terminal />
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
  </div></>)
}

export default App;
